<template>
    <div class="flex flex-col bg-white rounded py-4"
         :class="{
                'w-full': isSetup,
                'lg:w-[400px] px-6': !isSetup
            }"
    >
        <img :src="logoBlack" alt="" class="w-32 mx-auto mb-5" v-if="!isSetup"/>

        <p class="text-3xl font-bold text-black mb-5">
            Pro
            <br>
            <AppBadge class="mr-1 bg-primary text-white">
                Outbound Calls
            </AppBadge>
            <AppBadge>
                Workflows
            </AppBadge>
        </p>

        <table class="text-left align-middle">
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                    Everything in Base &amp; Business
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                    5 Agents
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            15 Workflows
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            Make Outbound Calls and Launch Outbound Campaigns
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            Run Custom GPT Prompts
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            <b>500 Minutes Included</b>, additional minutes 30 cents per minute.
                                        </span>
                </td>
            </tr>
        </table>

        <hr class="mt-auto mb-3">
        <p
            class="text-4xl"
        >
            {{ products[2].formatted_price }}/mo
        </p>
        <p class="text-sm">
            + 30 cents per minute (500 mins included)
        </p>

        <div class="py-4 lg:py-8 flex items-center justify-center gap-x-6">
            <PrimaryButton
                v-if="!isSetup"
                @click="() => router.visit(route('register.with-price', {price: products[2].value}), { method: 'get' })"
                class="scale-125 lg:scale-150 origin-center">
                Start Your Free Trial
            </PrimaryButton>
            <PrimaryButton
                v-if="isSetup"
                @click="emit('submit', products[2].value)"
                :disabled="loading"
                :class="{
                    'opacity-25': loading
                }"
                class="lg:scale-125"
            >
                <slot>
                    Start Free Trial
                </slot>
            </PrimaryButton>
        </div>
        <p class="text-xs lg:-mt-5" v-if="!isSetup">
            Start your free, no-obligation 7 day trial today.
        </p>
    </div>

</template>
<script setup lang="ts">
import logoBlack from "../../../../images/logo-black.svg";
import {router, usePage} from "@inertiajs/vue3";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {CheckCircleIcon} from "@heroicons/vue/20/solid/index";
import AppBadge from "@/Components/AppBadge.vue";
//import {route} from "ziggy-js";
const products = usePage().props.products


defineProps(['isSetup', 'loading'])

const emit = defineEmits(['submit'])
</script>
