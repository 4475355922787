<template>
    <!--    <h2 class="text-3xl text-white text-center font-bold mb-6">-->
    <!--        Hear John in Action.-->
    <!--    </h2>-->
    <div class="relative bg-gray-200 rounded-2xl p-6 shrink-0"
         ref="waveContainer"
    >
        <div
            @click="playPause"
            :class="{ ' bottom-2 -right-4 left-auto translate-y-0 top-auto text-white': hidePlayButton}"
            class="absolute z-10 cursor-pointer bg-primary hover:bg-white size-14 rounded-full flex items-center justify-center left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 border-4 border-primary">

            <MdiIcon :icon="mdiPlayCircle" v-if="!hidePlayButton"
                     class="size-20 scale-125 hover:text-primary text-accent"/>
            <MdiIcon :icon="mdiPauseCircle" v-if="hidePlayButton"
                     class="size-20 scale-125 hover:text-primary text-accent"/>

        </div>

        <div class="relative"
             :class="{'': !hidePlayButton}"
        >

            <div class="relative mb-4 overflow-x-hidden overflow-y-auto w-full h-32" ref="speechContainer">
                <div class="speech system active" data-key="0" data-show="intro">
                    <span class="caret right"></span>
                    <span class="text">
                    Calls may be recored for quality purposes.
                        </span>
                </div>

                <div class="speech system" data-key="2500" data-show="intro">
                    <span class="caret right"></span>
                    <span class="text">
                   Thanks for calling FitFlex. This is Mary. I'm a virtual receptionist. How can I help you today?
                        </span>
                </div>

                <div class="speech user" data-key="8000">
                    <span class="caret left"></span>
                    <span class="text">
                    Hey there. Good afternoon. My name is Tom. I got your number from a friend, and I would love to learn more about your services.
                    </span>
                </div>

                <div class="speech system" data-key="17000" >
                    <span class="caret right"></span>
                    <span class="text">
                   Hi, Tom.

Good afternoon. I'd be happy to give you the lowdown on what we offer. Here at Mobile Fitness Coach, we focus on personalized on the go fitness programs. Our certified trainers provide customized workout routines, nutrition guidance, and 1 on 1 coaching through both mobile sessions and virtual check ins.
                    </span>
                </div>

                <div class="speech system" data-key="34000">
                    <span class="caret right"></span>
                    <span class="text">
                  It's all about making fitness convenient and effective for your lifestyle.

                        Is there anything specific you'd like to know more about?
                    </span>
                </div>

                <div class="speech user" data-key="40000">
                    <span class="caret left"></span>
                    <span class="text">
                    Oh, that sounds great. I would love to schedule a session with you guys.
                    </span>
                </div>

                <div class="speech system" data-key="47000" >
                    <span class="caret right"></span>
                    <span class="text">
                    I'm happy to hear you are interested in booking a training session. Please tell me your name.
                    </span>
                </div>

                <div class="speech user" data-key="54000">
                    <span class="caret left"></span>
                    <span class="text">
                    Yeah. My name is Tom Doe.
                    </span>
                </div>

                <div class="speech system" data-key="58000">
                    <span class="caret right"></span>
                    <span class="text">
                    Thank you, Tom Doe. One moment.
                    </span>
                </div>

                <div class="speech system" data-key="62000">
                    <span class="caret right"></span>
                    <span class="text">
                    Alright, Tom.

Let's get you scheduled. How about Monday, November 25th? I can offer you either an 8 AM or 9 AM slot. Which one works better for you?
                    </span>
                </div>

                <div class="speech user" data-key="71000">
                    <span class="caret left"></span>
                    <span class="text">
                    8 AM works perfect.
                    </span>
                </div>

                <div class="speech system" data-key="77000">
                    <span class="caret right"></span>
                    <span class="text">
                    Great. To finalize your session, could you please spell out your email address for me? You'll also receive a text confirmation once we're all set.
                    </span>
                </div>

                <div class="speech user" data-key="86000">
                    <span class="caret left"></span>
                    <span class="text">
                  Okay. Yeah. It's tom@icloud.com.
                    </span>
                </div>

                <div class="speech system" data-key="94000" data-show="message">
                    <span class="caret right"></span>
                    <span class="text">
                    Please give me a moment to schedule your appointment. Awesome. Your session for 8 AM on Monday, November 25th is all set. You'll get a text confirmation soon.

                        Is there anything else I can help you with, Tom?
                    </span>
                </div>

                <div class="speech user" data-key="110000">
                    <span class="caret left"></span>
                    <span class="text">
                      That's it. Thank you.
                    </span>
                </div>

                <div class="speech system" data-key="115000">
                    <span class="caret right"></span>
                    <span class="text">
             Goodbye. Please call back if you have any questions.
                    </span>
                </div>

            </div>
        </div>

        <div class="bg-black px-4 py-2 rounded-2xl relative">
            <div ref="container"></div>
        </div>
    </div>

    <div class="overflow-hidden mt-4 relative h-[88px] shrink-0 w-full">
        <div id="intro" class="activity active">
            <p class="text-xs">
                Behind the Scenes...
            </p>

            <div class="mt-2 z-10 flex bg-white items-center gap-4 p-2 rounded-2xl text-xs">

                <div class="aspect-square bg-gray-700 text-white p-1 rounded">
                    <SpeakerWaveIcon class="size-6"/>
                </div>

                <span>
                    Click to play this example
                </span>

            </div>
        </div>
        <div id="message" class="activity">
            <p class=" text-xs">
                Behind the Scenes...
            </p>

            <div class="mt-2 z-10 flex bg-white items-center gap-4 p-2 rounded-2xl">

                <div class="aspect-square bg-green-700 text-white p-1 rounded">
                    <EnvelopeIcon class="size-6"/>
                </div>

                Bot is Sending Text Message to Caller...

                <CircularLoader class="size-8 ml-auto mr-1"/>
            </div>
        </div>
    </div>
</template>
<style scoped>
.speech {
    @apply bg-gray-300 absolute px-4 py-2 transition duration-300 bottom-0;
    @apply italic rounded-2xl w-4/5 text-sm lg:text-base;
}

.speech .caret {
    @apply absolute top-1/2;
}

.system {
    @apply right-0 bg-secondary text-white;
    transform: translateX(calc(100% + 20px));
    margin-right: 19px;
}

.system .caret {
    @apply right-0;
    transform: translateX(18px) translateY(-50%);
}

.user {
    transform: translateX(calc(-100% - 20px));
    margin-left: 19px;
}

.user .caret {
    @apply mr-auto;
    transform: translateX(-36px) translateY(-50%);
}

.system.active {
    transform: translateX(0);
}


.user.active {
    transform: translateX(0);
}

.caret {
    width: 0;
    height: 0;
    display: inline-block;
    border: 10px solid transparent;
}

.caret-large {
    border-width: 35px;
}

.caret.down {
    border-top-color: black;
}

.system .caret.right {
    @apply border-l-secondary;
}

.caret.right {
    border-left-color: #fff;
}

.caret.up {
    border-bottom-color: black;
}

.caret.left {
    @apply border-r-gray-300;
}

.activity {
    @apply absolute -translate-y-full duration-300 w-full text-xs;
}

.activity.active {
    @apply translate-y-0;
}
</style>
<script setup>
// Custom rendering function
import {onMounted, ref} from "vue";
import {EnvelopeIcon, GlobeAmericasIcon, SpeakerWaveIcon, PhoneIcon, PauseIcon} from "@heroicons/vue/20/solid";
import {mdiPlayCircle, mdiPauseCircle} from "@mdi/js";
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js'

const container = ref()
const wavesurfer = ref();
const speechContainer = ref();
import WaveSurfer from 'wavesurfer.js'
import CircularLoader from "@/Components/CircularLoader.vue";
import MdiIcon from "@/Components/MdiIcon.vue";

const waveContainer = ref()

const hidePlayButton = ref(false);
const playPause = () => {
    wavesurfer.value.playPause()
    hidePlayButton.value = !wavesurfer.value.media.paused;
    resetBubbles()

    if (!wavesurfer.value.media.paused) {
        playBubbles()
    }
}

const resetBubbles = () => {
    timeouts.value.map((t) => clearTimeout(t))
    timeouts.value = []

    const speechBubbles = waveContainer.value.querySelectorAll('.speech')
    speechBubbles.forEach((bubble) => {
        bubble.classList.remove('active')
    })

    speechBubbles[0].classList.add('active')
}

const timeouts = ref([]);

const playBubbles = (index) => {
    const speechBubbles = waveContainer.value.querySelectorAll('.speech')
    speechBubbles.forEach((bubble) => {
        const timeout = parseInt(bubble.dataset.key)
        const showActivity = bubble.dataset.show;
        const t = setTimeout(() => {
            // Remove from all other bubbles
            speechBubbles.forEach((b) => b.classList.remove('active'))
            bubble.classList.add('active')

            const textNode = bubble.querySelector('.text');

            const text = textNode.innerHTML;
            const words = text.split('');
            const duration = words.length * 100;
            textNode.innerHTML = '';
            words.forEach((word, i) => {
                setTimeout(() => {
                    textNode.innerHTML += word + '';
                }, i * 50)
            })

            document.querySelectorAll('.activity').forEach((activity) => activity.classList.remove('active'))
            if (showActivity) {
                document.getElementById(showActivity).classList.add('active')
            }
        }, timeout)
        timeouts.value.push(t)
    })
}

const currentTime = ref(0)

onMounted(() => {
    let maxHeight = 0;

    setTimeout(() => {
        const speechBubbles = document.querySelectorAll('.speech')

        speechBubbles.forEach((bubble) => {
            // get height
            const h = bubble.clientHeight;
            if (h > maxHeight) {
                maxHeight = h;
            }
        })

        // speechContainer.value.style.height = `${maxHeight}px`;
    }, 150)

    wavesurfer.value = WaveSurfer.create({
        container: container.value,
        waveColor: 'rgb(255, 255, 255)',
        progressColor: 'rgb(255, 255, 255)',
        url: '/static/callsamples/fitnesscoach.mp3',
        interact: false,
        height: 100,
        /**
         * Render a waveform as a squiggly line
         * @see https://css-tricks.com/making-an-audio-waveform-visualizer-with-vanilla-javascript/
         */
        renderFunction: (channels, ctx) => {
            const {width, height} = ctx.canvas
            const scale = channels[0].length / width
            const step = 6

            ctx.translate(0, height / 2)
            ctx.strokeStyle = ctx.fillStyle
            ctx.beginPath()

            for (let i = 0; i < width; i += step * 2) {
                const index = Math.floor(i * scale)
                const value = Math.abs(channels[0][index])
                let x = i
                let y = value * height + 10

                ctx.moveTo(x, 0)
                ctx.lineTo(x, y)
                ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, true)
                ctx.lineTo(x + step, 0)

                x = x + step
                y = -y
                ctx.moveTo(x, 0)
                ctx.lineTo(x, y)
                ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, false)
                ctx.lineTo(x + step, 0)
            }

            ctx.stroke()
            ctx.closePath()
        },
    })

    wavesurfer.value.on('interaction', (e) => {
        wavesurfer.value.play()
    })

    /** When the audio starts playing */
    wavesurfer.value.on('play', () => {
        hidePlayButton.value = true
    })

    /** When the audio pauses */
    wavesurfer.value.on('pause', () => {
        hidePlayButton.value = false
    })

})
</script>
