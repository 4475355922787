<template>
    <!--    <h2 class="text-3xl text-white text-center font-bold mb-6">-->
    <!--        Hear John in Action.-->
    <!--    </h2>-->
    <div class="relative bg-gray-200 rounded-2xl p-6 shrink-0"
         ref="waveContainer"
    >
        <div
            @click="playPause"
            :class="{ ' bottom-2 -right-4 left-auto translate-y-0 top-auto text-white': hidePlayButton}"
            class="absolute z-10 cursor-pointer bg-primary hover:bg-white size-14 rounded-full flex items-center justify-center left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 border-4 border-primary">

            <MdiIcon :icon="mdiPlayCircle" v-if="!hidePlayButton"
                     class="size-20 scale-125 hover:text-primary text-accent"/>
            <MdiIcon :icon="mdiPauseCircle" v-if="hidePlayButton"
                     class="size-20 scale-125 hover:text-primary text-accent"/>

        </div>

        <div class="relative"
             :class="{'': !hidePlayButton}"
        >

            <div class="relative mb-4 overflow-x-hidden overflow-y-auto w-full h-32" ref="speechContainer">
                <div class="speech system active" data-key="0" data-show="intro">
                    <span class="caret right"></span>
                    <span class="text">
                    Calls may be recored for quality purposes.
                        </span>
                </div>

                <div class="speech system" data-key="2500" data-show="intro">
                    <span class="caret right"></span>
                    <span class="text">
                    Hello, thank you for your interest in Green Fundraising Demo!
                        I'm here to help, how can I assist you?
                        </span>
                </div>

                <div class="speech user" data-key="8000">
                    <span class="caret left"></span>
                    <span class="text">
                    Hi. Good morning. Yeah. I was looking at the fundraising event, and I would love to get more information.
                    </span>
                </div>

                <div class="speech system" data-key="15600" data-show="website">
                    <span class="caret right"></span>
                    <span class="text">
                    Of Course. Let me send you some more information about our fundraising event by email. Could you please provide your email address? Feel free to spell it out if that's easier for you.
                    </span>
                </div>

                <div class="speech user" data-key="26000">
                    <span class="caret left"></span>
                    <span class="text">
                    Okay. Sure. It's james@example.com
                    </span>
                </div>

                <div class="speech system" data-key="37000" data-show="message">
                    <span class="caret right"></span>
                    <span class="text">
                    Great. Just to confirm, that's james@example.com. Is that correct?
                    </span>
                </div>

                <div class="speech user" data-key="44000">
                    <span class="caret left"></span>
                    <span class="text">
                    That's correct.
                    </span>
                </div>

                <div class="speech system" data-key="50000">
                    <span class="caret left"></span>
                    <span class="text">
                    Hold on a second. Thanks, and what is your name?
                    </span>
                </div>

                <div class="speech user" data-key="56000">
                    <span class="caret left"></span>
                    <span class="text">
                    My name is James Doe.
                    </span>
                </div>

                <div class="speech system" data-key="60000">
                    <span class="caret left"></span>
                    <span class="text">
                    Thanks, James Doe.

Give me a second to send your email. One moment. Alright, James. I've sent the information to your email. Is there anything else you need help with?
                    </span>
                </div>

                <div class="speech user" data-key="70000">
                    <span class="caret left"></span>
                    <span class="text">
                    Actually, can I speak to somebody right now?
                    </span>
                </div>

                <div class="speech system" data-key="75000">
                    <span class="caret left"></span>
                    <span class="text">
                  No problem. I will connect you to one of our agents.
                    </span>
                </div>
            </div>

            <div class="bg-black px-4 py-2 rounded-2xl relative">
                <div ref="container"></div>
            </div>
        </div>

    </div>

    <div class="overflow-hidden mt-4 relative h-[88px] shrink-0 w-full">
        <div id="intro" class="activity active">
            <p class="text-xs">
                Behind the Scenes...
            </p>

            <div class="mt-2 z-10 flex bg-white items-center gap-4 p-2 rounded-2xl text-xs">

                <div class="aspect-square bg-gray-700 text-white p-1 rounded">
                    <SpeakerWaveIcon class="size-6"/>
                </div>

                <span>
                    Click to play this example
                </span>

            </div>
        </div>
        <div id="website" class="activity ">
            <p class="text-xs">
                Behind the Scenes...
            </p>

            <div class="mt-2 z-10 flex bg-white items-center gap-4 p-2 rounded-2xl text-xs">

                <div class="aspect-square bg-blue-700 text-white p-1 rounded">
                    <GlobeAmericasIcon class="size-6"/>
                </div>

                <span>
                Bot is checking
                <a target="_blank"
                   class="link"
                   href="https://www.greetmate.ai/sample/real-estate.html">https://www.greetmate.ai/sample/real-estate.html</a>
                ...
                    </span>

                <CircularLoader class="size-8 ml-auto mr-1"/>
            </div>
        </div>
        <div id="message" class="activity">
            <p class=" text-xs">
                Behind the Scenes...
            </p>

            <div class="mt-2 z-10 flex bg-white items-center gap-4 p-2 rounded-2xl">

                <div class="aspect-square bg-green-700 text-white p-1 rounded">
                    <EnvelopeIcon class="size-6"/>
                </div>

                Bot is Sending Text Message to Caller...

                <CircularLoader class="size-8 ml-auto mr-1"/>
            </div>
        </div>
        <div id="agent" class="activity">
            <p class=" text-xs">
                Behind the Scenes...
            </p>

            <div class="mt-2 z-10 flex bg-white items-center gap-4 p-2 rounded-2xl">

                <div class="aspect-square bg-green-700 text-white p-1 rounded">
                    <PhoneIcon class="size-6"/>
                </div>

                Bot is Calling your Number with a Call Summary, you can accept or decline the call.

                <CircularLoader class="size-8 ml-auto mr-1"/>
            </div>
        </div>
    </div>
</template>
<style scoped>
.speech {
    @apply bg-gray-300 absolute px-4 py-2 transition duration-300 bottom-0;
    @apply italic rounded-2xl w-4/5 text-sm lg:text-base;
}

.speech .caret {
    @apply absolute top-1/2;
}

.system {
    @apply right-0 bg-secondary text-white;
    transform: translateX(calc(100% + 20px));
    margin-right: 19px;
}

.system .caret {
    @apply right-0;
    transform: translateX(18px) translateY(-50%);
}

.user {
    transform: translateX(calc(-100% - 20px));
    margin-left: 19px;
}

.user .caret {
    @apply mr-auto;
    transform: translateX(-36px) translateY(-50%);
}

.system.active {
    transform: translateX(0);
}


.user.active {
    transform: translateX(0);
}

.caret {
    width: 0;
    height: 0;
    display: inline-block;
    border: 10px solid transparent;
}

.caret-large {
    border-width: 35px;
}

.caret.down {
    border-top-color: black;
}

.system .caret.right {
    @apply border-l-secondary;
}

.caret.right {
    border-left-color: #fff;
}

.caret.up {
    border-bottom-color: black;
}

.caret.left {
    @apply border-r-gray-300;
}

.activity {
    @apply absolute -translate-y-full duration-300 w-full text-xs;
}

.activity.active {
    @apply translate-y-0;
}
</style>
<script setup>
// Custom rendering function
import {onMounted, ref} from "vue";
import {EnvelopeIcon, GlobeAmericasIcon, SpeakerWaveIcon, PhoneIcon, PauseIcon} from "@heroicons/vue/20/solid";
import {mdiPlayCircle, mdiPauseCircle} from "@mdi/js";
import Hover from 'wavesurfer.js/dist/plugins/hover.esm.js'

const container = ref()
const wavesurfer = ref();
const speechContainer = ref();
import WaveSurfer from 'wavesurfer.js'
import CircularLoader from "@/Components/CircularLoader.vue";
import MdiIcon from "@/Components/MdiIcon.vue";

const waveContainer = ref()

const hidePlayButton = ref(false);
const playPause = () => {
    wavesurfer.value.playPause()
    hidePlayButton.value = !wavesurfer.value.media.paused;
    resetBubbles()

    if (!wavesurfer.value.media.paused) {
        playBubbles()
    }
}

const resetBubbles = () => {
    timeouts.value.map((t) => clearTimeout(t))
    timeouts.value = []

    const speechBubbles = waveContainer.value.querySelectorAll('.speech')
    speechBubbles.forEach((bubble) => {
        bubble.classList.remove('active')
    })

    speechBubbles[0].classList.add('active')
}

const timeouts = ref([]);

const playBubbles = (index) => {
    const speechBubbles = waveContainer.value.querySelectorAll('.speech')
    speechBubbles.forEach((bubble) => {
        const timeout = parseInt(bubble.dataset.key)
        const showActivity = bubble.dataset.show;
        const t = setTimeout(() => {
            // Remove from all other bubbles
            speechBubbles.forEach((b) => b.classList.remove('active'))
            bubble.classList.add('active')

            const textNode = bubble.querySelector('.text');

            const text = textNode.innerHTML;
            const words = text.split('');
            const duration = words.length * 100;
            textNode.innerHTML = '';
            words.forEach((word, i) => {
                setTimeout(() => {
                    textNode.innerHTML += word + '';
                }, i * 50)
            })

            document.querySelectorAll('.activity').forEach((activity) => activity.classList.remove('active'))
            if (showActivity) {
                document.getElementById(showActivity).classList.add('active')
            }
        }, timeout)
        timeouts.value.push(t)
    })
}

const currentTime = ref(0)

onMounted(() => {
    let maxHeight = 0;

    setTimeout(() => {
        const speechBubbles = document.querySelectorAll('.speech')

        speechBubbles.forEach((bubble) => {
            // get height
            const h = bubble.clientHeight;
            if (h > maxHeight) {
                maxHeight = h;
            }
        })

        // speechContainer.value.style.height = `${maxHeight}px`;
    }, 150)

    wavesurfer.value = WaveSurfer.create({
        container: container.value,
        waveColor: 'rgb(255, 255, 255)',
        progressColor: 'rgb(255, 255, 255)',
        url: '/static/callsamples/fundraising1.mp3',
        interact: false,
        height: 100,
        /**
         * Render a waveform as a squiggly line
         * @see https://css-tricks.com/making-an-audio-waveform-visualizer-with-vanilla-javascript/
         */
        renderFunction: (channels, ctx) => {
            const {width, height} = ctx.canvas
            const scale = channels[0].length / width
            const step = 6

            ctx.translate(0, height / 2)
            ctx.strokeStyle = ctx.fillStyle
            ctx.beginPath()

            for (let i = 0; i < width; i += step * 2) {
                const index = Math.floor(i * scale)
                const value = Math.abs(channels[0][index])
                let x = i
                let y = value * height + 10

                ctx.moveTo(x, 0)
                ctx.lineTo(x, y)
                ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, true)
                ctx.lineTo(x + step, 0)

                x = x + step
                y = -y
                ctx.moveTo(x, 0)
                ctx.lineTo(x, y)
                ctx.arc(x + step / 2, y, step / 2, Math.PI, 0, false)
                ctx.lineTo(x + step, 0)
            }

            ctx.stroke()
            ctx.closePath()
        },
    })

    wavesurfer.value.on('interaction', (e) => {
        wavesurfer.value.play()
    })

    /** When the audio starts playing */
    wavesurfer.value.on('play', () => {
        hidePlayButton.value = true
    })

    /** When the audio pauses */
    wavesurfer.value.on('pause', () => {
        hidePlayButton.value = false
    })

})
</script>
