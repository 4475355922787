<template>
    <PrimaryButton class="scale-125 origin-left"
                   @click="scrollToDemoCallSection"
    >
        Try a free test conversation
    </PrimaryButton>
</template>
<script setup>
import PrimaryButton from "@/Components/PrimaryButton.vue";

const scrollToDemoCallSection = () => {
    const top = document.querySelector('#democall').offsetTop
    window.scrollTo({ top, behavior: 'smooth' })
}
</script>
