<template>
    <div class="container gm-padding-y" id="features">
        <div class="flex flex-col gap-6 lg:grid lg:grid-cols-3 lg:gap-8 lg:items-end">
            <div>
                <h3 class="">
                    Infinite Possibilities.
                </h3>
            </div>
            <div>
                <p class="text-sm">
                    Some examples of how Greetmate.ai can help you.
                </p>
            </div>
        </div>

        <div class="mt-4 lg:mt-12 bg-gray-100 px-6 py-2 rounded-lg">
            <div class="sm:hidden pt-4">
                <label for="tabs" class="">Choose a Feature to Learn More</label>
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select v-model="currentTabSelect" id="tabs" name="tabs"
                        class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm">
                    <option v-for="tab in tabs" :key="tab.name" :selected="tab.current.value">{{ tab.name }}</option>
                </select>
            </div>
            <div class="hidden sm:block">
                <div class="">
                    <nav class="-mb-px flex flex-wrap gap-2" aria-label="Tabs">
                        <a v-for="tab in tabs" :key="tab.name"
                           @click="currentTab = tab"
                           class="flex items-center gap-1 justify-center"
                           :class="[tab.current.value ? 'border-primary text-black bg-white text-primary' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border rounded-lg px-2 py-4 sm:text-xs lg:text-base font-medium cursor-pointer']"
                           :aria-current="tab.current.value ? 'page' : undefined">
                            <MdiIcon :icon="tab.icon" class="size-6 mr-1"/>
                            {{ tab.name }}
                        </a>
                    </nav>
                </div>
            </div>

            <component :is="currentTab?.component"/>

        </div>
    </div>
</template>

<script setup>
import {computed, markRaw, ref} from "vue";
import TabInboundCalls from "@/Pages/Index/Tabs/TabInboundCalls.vue";
import MdiIcon from "@/Components/MdiIcon.vue";
import {mdiPhoneIncoming, mdiPhoneOutgoing, mdiCalendar, mdiDatabase, mdiApi, mdiFaceAgent} from "@mdi/js";
import TabOutboundCalls from "@/Pages/Index/Tabs/TabOutboundCalls.vue";
import TabAppointmentScheduling from "@/Pages/Index/Tabs/TabAppointmentScheduling.vue";
import TabKnowledgeBase from "@/Pages/Index/Tabs/TabKnowledgeBase.vue";
import TabIntegrations from "@/Pages/Index/Tabs/TabIntegrations.vue";
import TabCustomerService from "@/Pages/Index/Tabs/TabCustomerService.vue";

const currentTab = ref({name: 'Inbound Calls', component: markRaw(TabInboundCalls)});

const tabs = [
    {
        name: 'Inbound Calls',
        component: markRaw(TabInboundCalls),
        current: computed(() => currentTab.value.name === 'Inbound Calls'),
        icon: mdiPhoneIncoming
    },
    {
        name: 'Outbound Calls',
        component: markRaw(TabOutboundCalls),
        current: computed(() => currentTab.value.name === 'Outbound Calls'),
        icon: mdiPhoneOutgoing
    },
    {
        name: 'Integrations',
        icon: mdiApi,
        component: markRaw(TabIntegrations), current: computed(() => currentTab.value.name === 'Integrations')
    },
    {
        name: 'Appointment Scheduling',
        component: markRaw(TabAppointmentScheduling),
        current: computed(() => currentTab.value.name === 'Appointment Scheduling'),
        icon: mdiCalendar
    },
    {
        name: 'Knowledge Base', component: markRaw(TabKnowledgeBase),
        icon: mdiDatabase,
        current: computed(() => currentTab.value.name === 'Knowledge Base')
    },
    {
        name: 'Customer Service', component: markRaw(TabCustomerService),
        icon: mdiFaceAgent,
        current: computed(() => currentTab.value.name === 'Customer Service')
    },
]

const currentTabSelect = computed({
    get: () => currentTab.value.name,
    set: (value) => currentTab.value = tabs.find(tab => tab.name === value)
})
</script>
