<template>
    <div class="pricing relative " id="pricing">
        <img :src="pattern" alt="" class="object-cover inset-0 absolute z-0 w-full h-full"/>

        <div class="gm-padding-y">
            <div class="container relative z-10 pb-16">
                <div class="flex flex-col gap-6 lg:grid lg:grid-cols-3 lg:gap-8 lg:items-end">
                    <div>
                        <h2 class="text-3xl lg:text-5xl font-bold text-white">
                            A.I. Call Platform for Everybody.
                        </h2>
                    </div>
                    <div>
                        <p class="text-sm text-white">
                            Start your free trial today and get a local phone number with your area code.
                        </p>
                    </div>
                </div>

                <div class="-mx-4 px-6 text-center py-4 lg:py-12">
                    <div
                        class="max-w-full flex mx-auto my-12 justify-center duration-300 gap-6 lg:gap-12 flex-col md:flex-row">
                        <ProductBase :is-setup="false"/>
                        <ProductBusiness :is-setup="false"/>
                        <ProductPro :is-setup="false"/>
                    </div>

                    <ProductOnboarding/>

                    <ProductCustom/>

                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {CheckCircleIcon} from "@heroicons/vue/20/solid/index.js";
import pattern from '@/../images/patterntest.jpg?format=webp';

import logoBlack from "@/../images/logo-black.svg";
import {router, usePage} from "@inertiajs/vue3";
import ProductBase from "@/Pages/Index/Pricing/ProductBase.vue";
import ProductCustom from "@/Pages/Index/Pricing/ProductCustom.vue";
import ProductPro from "@/Pages/Index/Pricing/ProductPro.vue";
import ProductBusiness from "@/Pages/Index/Pricing/ProductBusiness.vue";
import ProductOnboarding from "@/Pages/Index/Pricing/ProductOnboarding.vue";



const products = usePage().props.products

</script>
