<template>
    <div class="flex flex-col bg-white rounded py-4 px-6"
         :class="{
                'w-full border-2 border-primary': isSetup,
                'lg:w-[400px]': !isSetup
            }"
    >
        <img :src="logoBlack" alt="" class="w-32 mx-auto mb-5" v-if="!isSetup"/>

        <p class="text-3xl font-bold text-black mb-5">
            Business
            <br>
            <AppBadge class="mr-1 bg-primary text-white">
                Most Popular
            </AppBadge>
            <AppBadge>
                Workflows
            </AppBadge>
        </p>

        <table class="text-left align-middle">
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                    Everything in Base
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pr-4 pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                    1 Agent
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            5 Workflows
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            Schedule Appointments with Google Calendar
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            Webhooks
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                          Knowledge Base to add Documents and Websites for Retrieval Augumented Generation
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <CheckCircleIcon class="size-6"/>
                </td>
                <td class="pb-2">
                                        <span>
                                            <b>200 Minutes Included</b>, additional minutes 30 cents per minute.
                                        </span>
                </td>
            </tr>
            <tr class="align-middle">
                <td class="pb-2">
                    <MdiIcon :icon="mdiCloseCircleOutline" class="size-6 text-red-500"/>
                </td>
                <td class="pb-2">
                    No outbound calls
                </td>
            </tr>
        </table>

        <hr class="mt-auto mb-3"/>

        <p
            class="text-4xl"
        >
            {{ products[1].formatted_price }}/mo
        </p>
        <p class="text-sm">
            + 30 cents per minute (200 mins included)
        </p>

        <div class="py-4 lg:py-8 flex items-center justify-center gap-x-6">
            <PrimaryButton
                v-if="!isSetup"
                @click="() => router.visit(route('register.with-price', {price: products[1].value}), { method: 'get' })"
                class="scale-125 lg:scale-150 origin-center">
                <slot>
                    Start Free Trial
                </slot>
            </PrimaryButton>
            <PrimaryButton
                v-if="isSetup"
                @click="emit('submit', products[1].value)"
                :disabled="loading"
                :class="{
                    'opacity-25': loading
                }"
                class="lg:scale-125"
            >
                <slot>
                    Start Free Trial
                </slot>
            </PrimaryButton>
        </div>
        <p class="text-xs lg:-mt-5" v-if="!isSetup">
            Start your free, no-obligation 7 day trial today.
        </p>
    </div>

</template>
<script setup lang="ts">
import logoBlack from "../../../../images/logo-black.svg";
import {router, usePage} from "@inertiajs/vue3";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import {CheckCircleIcon} from "@heroicons/vue/20/solid/index";
import AppBadge from "@/Components/AppBadge.vue";
import {mdiCloseCircleOutline} from "@mdi/js";
import MdiIcon from "@/Components/MdiIcon.vue";

const products = usePage().props.products


defineProps(['isSetup', 'loading'])

const emit = defineEmits(['submit'])
</script>
