<template>
    <div class="">
        <swiper
            :modules="[Pagination, Navigation]"
            :grabCursor="true"
            :centeredSlides="true"
            :slidesPerView="slidesPerView"
            :space-between="30"
            :navigation="true"
            :pagination="true"
            :free-mode="false"
            :loop="true"
        >
            <swiper-slide>
                <RealWorldExample1/>
            </swiper-slide>
            <swiper-slide>
                <RealWorldExample2/>
            </swiper-slide>
            <swiper-slide>
                <RealWorldExample3/>
            </swiper-slide>
        </swiper>
    </div>
</template>
<style scoped>
.swiper-slide {
    opacity: .5;
    pointer-events: none;
}

.swiper-slide-active {
    opacity: 1;
    pointer-events: auto;
}
</style>
<script setup>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation, Pagination, Scrollbar, A11y, EffectCoverflow} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow';

import RealWorldExample2 from "@/Components/Marketing/RealWorldCalls/RealWorldExample2.vue";
import RealWorldExample1 from "@/Components/Marketing/RealWorldCalls/RealWorldExample1.vue";
import RealWorldExample3 from "@/Components/Marketing/RealWorldCalls/RealWorldExample3.vue";
import {onMounted, ref} from "vue";

const slidesPerView = ref(1)
onMounted(() => {
    if (window.innerWidth < 768) {
        slidesPerView.value = 1
    } else {
        slidesPerView.value = 2
    }
})

const onSwiper = (swiper) => console.log(swiper);
const onSlideChange = () => console.log('slide change');
// Import Swiper styles
</script>
